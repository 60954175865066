<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__boqtemplate">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <project-select v-model="dataForm.prjId" placeholder="项目选择" @change="projectChange" type='authority' :defaultSelect="true"></project-select>
        </el-form-item>
        <el-form-item>
          <el-button-group v-if="dataForm.prjId" style="margin-top: -3px;">
            <toolbar-button role="add" v-if="$hasPermission('dfms:papercatalog:save')" @click="addChildNode({id: 0})"></toolbar-button>
            <toolbar-button role="import" v-if="$hasPermission('dfms:papercatalog:import')" @click="importHandle"></toolbar-button>
            <toolbar-button role="export" v-if="$hasPermission('dfms:papercatalog:export')" @click="exportHandle"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('dfms:papercatalog:delete')" @click="myDeleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table
        v-if="tableShow"
        ref="table"
        v-loading="dataListLoading"
        :data="dataList"
        row-key="id"
        lazy
        :load="loadTableData"
        @selection-change="dataListSelectionChangeHandle"
        highlight-current-row
        border
        style="width: 100%;"
        :row-style="{height: '0px'}"
        :cell-style="{padding: '2px 0'}">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="code" label="编号" header-align="center" min-width="150"></el-table-column>
        <el-table-column prop="name" label="名称" header-align="center" min-width="150"></el-table-column>
        <el-table-column prop="drawingNo" label="图号" header-align="center" align="center" min-width="150"></el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="160">
          <template slot-scope="{row}">
            <table-button role="addChild" v-if="$hasPermission('dfms:papercatalog:save')" @click="addChildNode(row)"></table-button>
            <table-button role="update" v-if="$hasPermission('dfms:papercatalog:update')" @click="addOrUpdateHandle(row.id)"></table-button>
            <table-button role="delete" v-if="$hasPermission('dfms:papercatalog:delete')" @click="myDeleteHandle(row.id, row.pid)"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @addNode="addNodeCallback" @updateNode="updateNodeCallback" @close="closeDialogHandle"></add-or-update>
      <!-- excel文件导入弹窗 -->
      <excel-import v-if="excelImportVisible" ref="excelImport" @refreshDataList="getDataList" url="/mps/paperCatalog/import"></excel-import>
    </div>
  </el-card>
</template>
<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './papercatalog-add-or-update'

export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: false,
        deleteURL: '/mps/paperCatalog',
        exportURL: `/mps/paperCatalog/export`,
        deleteSuccessCallback: this.deleteSuccessCallback
      },
      dataForm: {
        prjId: '',
        name: '',
        drawingNo: '',
      },
      // 存储已加载的节点的map
      treeNodeMap: new Map(),
      tableShow: true,
    }
  },
  components: {
    AddOrUpdate
  },
  methods: {
    // el-table懒加载节点
    loadTableData (data,node,resolve) {
      let pid = data.id
      // 将已加载的节点相关参数存入map，用于后续增加子节点、删除子节点时刷新父节点
      this.treeNodeMap.set(pid,{data,node,resolve})
      this.$http.get(
        '/mps/paperCatalog/findChildren',
        {
          params: {
            prjId: this.dataForm.prjId,
            pid: pid
          }
        }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          // 返回出错
          return this.$message.error(res.msg)
        }
        resolve(res.data);
        // 如果子节点数量为0，则说明这一次的load是在删除了最后一个子节点后进行的，需要删除lazyTreeNodeMap中对应的数据
        // 否则按照el-table的基础行为，此子节点删除后依然会显示在table中，视图不会更新
        if (res.data.length == 0) {
          this.$set(this.$refs.table.store.states.lazyTreeNodeMap,pid,[])
        }
      }).catch(() => {
        // 查询出错
        return this.$message.error('出错了')
      })
    },

    getDataList () {
      // 组件功能不同，重写了混入中的方法
      this.treeNodeMap = new Map()
      // 重置一些el-table缓存的变量
      // 此处如果不重置，可能会导致一些无法预料的情况
      // 例如：某些节点被展开过，刷新后依然展开，其中的数据是缓存的而不是最新的
      this.tableShow = false
      this.$nextTick(() => {
        this.tableShow = true
      })
      this.dataListLoading = true
      this.$http.get(
        '/mps/paperCatalog/findChildren',
        {
          params: {
            prjId: this.dataForm.prjId,
            pid: 0,
          }
        }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          // 返回出错
          return this.$message.error(res.msg)
        }
        this.dataList = res.data
        this.dataListLoading = false
      }).catch(() => {
        // 查询出错
        return this.$message.error('出错了')
      })
    },

    addNodeCallback (pid, row) {
      // 新增子节点后，将其父节点改为非叶节点，否则不出现展开按钮
      row.hasChildren = true
      this.refreshParentNode(pid)
    },

    updateNodeCallback (pid) {
      this.refreshParentNode(pid)
    },

    // 刷新父节点（只能刷新展开过的节点）
    refreshParentNode (pid) {
      let nodeInfo = this.treeNodeMap.get(pid)
      if (nodeInfo) {
        // 这个loading属性控制此节点在load时是否重新加载
        // 例：在删除某个子节点后，若父节点loading属性为false，则父节点重新加载后，被删除的子节点依然显示
        nodeInfo.node.loading = true
        this.loadTableData(nodeInfo.data,nodeInfo.node,nodeInfo.resolve)
      } else if (pid == 0) {
        this.getDataList()
      }
    },

    // 新增子节点
    addChildNode (row) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        // 此处将row传给子组件，作为新增后的回调方法的参数
        this.$refs.addOrUpdate.row = row
        this.$refs.addOrUpdate.dataForm.pid = row.id
        this.$refs.addOrUpdate.dataForm.parentName = row.name
        this.$refs.addOrUpdate.dataForm.prjId = this.dataForm.prjId
        this.$refs.addOrUpdate.init()
      })
    },
    deleteSuccessCallback({id, pid}) {
      if (id && pid) {
        this.refreshParentNode(pid)
      } else {
        this.getDataList()
      }
    },
    myDeleteHandle (id, pid) {
      this.deleteHandle(id, {
        deleteSuccessCallbackArgs: {id, pid},
        autoQuery: false
      })
    },
    projectChange (e) {
      this.dataForm.prjId = e.id
      this.getDataList()
    },
  }
}
</script>