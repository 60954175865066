<template>
  <el-dialog top="5vh" width="80%" append-to-body v-dialog-drag :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="small" label-width="120px">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="code" label="工程或费用编码">
            <el-input v-model="dataForm.code" placeholder="工程或费用编码"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="上级节点" prop="pid">
            <estgb-parent-select v-model="dataForm.pid" :mainId="dataForm.mainId"></estgb-parent-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="name" label="名称">
            <el-input v-model="dataForm.name" placeholder="名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="unitName" label="计量单位">
            <el-input v-model="dataForm.unitName" placeholder="计量单位"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="主要工作内容" prop="contents">
            <el-input type="textarea" v-model="dataForm.contents" :autosize="{ minRows: 3, maxRows: 10}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" v-model="dataForm.remark" :autosize="{ minRows: 3, maxRows: 5}"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item prop="keyCode" label="全码">
            <span style="color:coral;">{{ dataForm.keyCode }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="level" label="概算类型">
            <span v-if="dataForm.level == 1" style="color:rgb(34, 11, 240);">部</span>
            <span v-if="dataForm.level == 2" style="color:rgb(238, 197, 17);">项</span>
            <span v-if="dataForm.level == 3" style="color:blueviolet;">目</span>
            <span v-if="dataForm.level == 4" style="color:chocolate;">节</span>
            <span v-if="dataForm.level >= 5" style="color:green;">细目</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="sortNo" label="排序">
            <el-input-number v-model="dataForm.sortNo" controls-position="right" :min="0" label="排序号"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import EstgbParentSelect from './estgb-parent-select'
import debounce from 'lodash/debounce'
export default {
  data () {
    return {
      visible: false,
      defaultParentName: '无',
      dataForm: {
        id: '',
        mainId: '',
        pid: '',
        name: '',
        keyCode: '',
        code: '',
        unitName: '',
        level: 0,
        parentName: '',
        contents: '',
        remark: '',
        sortNo: 0,
      },
      row: {},
      oldPid:'' // 记录数据原始的上级节点ID
    }
  },
  components: {
    EstgbParentSelect
  },
  computed: {
    dataRule () {
      return {
        name: [
          {required: true,message: this.$t('validate.required'),trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        } else {
          // 获取最新全码
          this.getNewKeyCode()
          // 获取排序序号
          this.getNewSortNo()
        }
        if (!this.dataForm.parentName) {
          this.dataForm.parentName = this.defaultParentName
        }
      })
    },
    // 获取【全码】
    getNewKeyCode() {
      this.$http.get(`/mps/estGb/${this.dataForm.mainId}/${this.dataForm.pid}/newKeyCode`).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        if (!res.data) {
          return this.$message.error("全码自动生成失败！")
        }
        this.dataForm.keyCode = res.data
      }).catch(() => {
        // do nothing
      })
    },
    // 获取排序序号
    getNewSortNo() {
      this.$http.get(`/mps/estGb/${this.dataForm.mainId}/${this.dataForm.pid}/newSortNo`).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm.sortNo = res.data
      }).catch(() => {
        // do nothing
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get(`/mps/estGb/${this.dataForm.id}`).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        if (!this.dataForm.parentName) {
          this.dataForm.parentName = this.defaultParentName
        }
        this.oldPid = this.dataForm.pid
      }).catch(() => {
        // do nothing
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/mps/estGb', this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          if (this.dataForm.id) {
            this.$emit('updateNode', this.dataForm.pid, this.oldPid != this.dataForm.pid)
          } else {
            this.$emit('addNode', this.dataForm.pid,this.row)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 1000,
            onClose: () => {
              this.visible = false
            }
          })
        }).catch(() => {
          // do nothing
        })
      })
    },1000,{'leading': true, 'trailing': false})
  }
}
</script>
