<template>
  <div ref="myDiv" class="mod-__estgb">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
      <el-form-item>
        <el-button-group style="margin-top: -3px;">
          <toolbar-button role="addRoot" v-if="$hasPermission('mps:estGb:save')" @click="addChildNode({id: 0, level: 0})"></toolbar-button>
          <toolbar-button role="delete" v-if="$hasPermission('mps:estGb:delete')" @click="myDeleteHandle()"></toolbar-button>
          <toolbar-button name="刷新名称路径" icon="el-icon-refresh" v-if="$hasPermission('mps:estGb:update')" @click="refreshHandle"></toolbar-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-table
      v-if="tableShow"
      ref="table"
      v-loading="dataListLoading"
      :data="dataList"
      row-key="id"
      lazy
      :load="loadTableData"
      @selection-change="dataListSelectionChangeHandle"
      highlight-current-row
      border
      :max-height="tableHeight"
      style="width: 100%;"
      :row-style="{height: '0px'}"
      :cell-style="{padding: '2px 0'}">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="code" label="工程或费用编码" width="200" show-overflow-tooltip></el-table-column>
      <el-table-column prop="name" label="名称" min-width="150" show-overflow-tooltip></el-table-column>
      <el-table-column prop="unitName" label="计量单位" header-align="center" align="center" width="100" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="keyCode" label="全码" width="200" show-overflow-tooltip></el-table-column>
      <el-table-column prop="levelName" label="概算类型" header-align="center" align="center" width="100" show-overflow-tooltip>
        <template v-slot="{row}">
          <span v-if="row.level == 1" style="color:rgb(34, 11, 240);">部</span>
          <span v-if="row.level == 2" style="color:rgb(238, 197, 17);">项</span>
          <span v-if="row.level == 3" style="color:blueviolet;">目</span>
          <span v-if="row.level == 4" style="color:chocolate;">节</span>
          <span v-if="row.level >= 5" style="color:green;">细目</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="160">
        <template slot-scope="{row}">
          <table-button role="addChild" v-if="$hasPermission('mps:estGb:save')" @click="addChildNode(row)"></table-button>
          <table-button role="update" v-if="$hasPermission('mps:estGb:update')" @click="addOrUpdateHandle(row.id)"></table-button>
          <table-button role="delete" v-if="$hasPermission('mps:estGb:delete')" @click="myDeleteHandle(row.id, row.pid)"></table-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @addNode="addNodeCallback" @updateNode="updateNodeCallback" @close="closeDialogHandle"></add-or-update>
  </div>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './estgb-add-or-update'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: false,
        deleteURL: '/mps/estGb',
        exportURL: `/mps/estGb/export`,
        deleteWithRelatedFiles: false,
        deleteSuccessCallback: this.deleteSuccessCallback
      },
      dataForm: {
      },
      mainId: null, // 范本主表ID
      tableHeight: 600,
      // 存储已加载的节点的map
      treeNodeMap: new Map(),
      tableShow: true,
    }
  },
  components: {
    AddOrUpdate
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - 340
    })
  },
  methods: {
    // el-table懒加载节点
    loadTableData(data, node, resolve) {
      let pid = data.id
      // 将已加载的节点相关参数存入map，用于后续增加子节点、删除子节点时刷新父节点
      this.treeNodeMap.set(pid, {data, node, resolve})
      this.$http.get(
          '/mps/estGb/children',
          {
            params: {
              mainId: this.mainId,
              pid: pid
            }
          }
      ).then(({ data: res }) => {
        if (res.code !== 0) {
          // 返回出错
          return this.$message.error(res.msg)
        }
        resolve(res.data);
        // 如果子节点数量为0，则说明这一次的load是在删除了最后一个子节点后进行的，需要删除lazyTreeNodeMap中对应的数据
        // 否则按照el-table的基础行为，此子节点删除后依然会显示在table中，视图不会更新
        if (res.data.length == 0) {
          this.$set(this.$refs.table.store.states.lazyTreeNodeMap, pid, [])
        }
      }).catch(() => {
        // 查询出错
        return this.$message.error('出错了')
      })
    },
    getDataList() {
      this.treeNodeMap = new Map()
      // 重置一些el-table缓存的变量
      // 此处如果不重置，可能会导致一些无法预料的情况
      // 例如：某些节点被展开过，刷新后依然展开，其中的数据是缓存的而不是最新的
      this.tableShow = false
      this.$nextTick(() => {
        this.tableShow = true
      })
      this.dataListLoading = true
      this.$http.get(
          '/mps/estGb/children',
          {
            params: {
              mainId: this.mainId,
              pid: 0,
            }
          }
      ).then(({ data: res }) => {
        if (res.code !== 0) {
          // 返回出错
          return this.$message.error(res.msg)
        }
        this.dataList = res.data
        this.dataListLoading = false
      }).catch(() => {
        // 查询出错
        return this.$message.error('出错了')
      })
    },
    addNodeCallback(pid, row) {
      // 新增子节点后，将其父节点改为非叶节点，否则不出现展开按钮
      row.hasChildren = true
      this.refreshParentNode(pid)
    },
    updateNodeCallback(pid, isPidChanged) {
      if(isPidChanged){
        // 父节点修改，直接刷新列表
        this.getDataList()
      }else{
        // 父节点未修改，刷新该节点子节点列表
        this.refreshParentNode(pid)
      }
    },
    // 刷新父节点（只能刷新展开过的节点）
    refreshParentNode(pid) {
      let nodeInfo = this.treeNodeMap.get(pid)
      if (nodeInfo) {
        // 这个loading属性控制此节点在load时是否重新加载
        // 例：在删除某个子节点后，若父节点loading属性为false，则父节点重新加载后，被删除的子节点依然显示
        nodeInfo.node.loading = true
        this.loadTableData(nodeInfo.data, nodeInfo.node, nodeInfo.resolve)
      } else if (pid == 0) {
        this.getDataList()
      }
    },
    // 新增子节点
    addChildNode (row) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        // 此处将row传给子组件，作为新增后的回调方法的参数
        this.$refs.addOrUpdate.row = row
        this.$refs.addOrUpdate.dataForm.mainId = this.mainId
        this.$refs.addOrUpdate.dataForm.pid = row.id
        this.$refs.addOrUpdate.dataForm.parentName = row.name
        this.$refs.addOrUpdate.dataForm.level = (row.level + 1)
        this.$refs.addOrUpdate.init()
      })
    },
    deleteSuccessCallback({id,pid}) {
      if (id && pid) {
        this.refreshParentNode(pid)
      } else {
        this.getDataList()
      }
    },
    // 删除
    myDeleteHandle (id, pid) {
      this.deleteHandle(id, {deleteSuccessCallbackArgs: {id,pid}, autoQuery: false})
    },
    // 递归刷新 pids,pnames
    refreshHandle () {
        this.$confirm('您确定要刷新所有分项的名称路径吗？').then(() => {
          const loading = this.$loading({
            lock: true,
            text: '请稍候...',
            spinner: 'el-icon-loading',
            customClass: 'my-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          this.$http.put(`/mps/estGb/${this.mainId}/refresh`).then(({data: res}) => {
            if (res.code != 0) {
              return this.$alert(res.msg,'',{
                confirmButtonText: '关闭',
                type: "error"
              });
            }
            this.$alert('已全部刷新！','',{
              confirmButtonText: '关闭',
              type: "success"
            });
          }).finally(() => {
            loading.close()
          })
        }).catch(() => {
          //  do nothing
        });
      },
  }
}
</script>
