<template>
  <div class="aui-wrapper aui-page__login">
    <div class="aui-content__wrapper">
      <main class="aui-content">
        <div class="login-header">
          <!-- <h2 class="login-brand">业主项目管理平台</h2> -->
        </div>
        <div class="login-body">
          <!-- <h3 class="login-title">{{ $t('login.title') }}</h3> -->
          <img :src="require(`@/assets/img/login_logo_dglq.jpg`)" height="70px"><h3 class="login-title" style="font-weight: bold;">工程建设数字化管理平台</h3>
          <!-- <img :src="require(`@/assets/img/login_logo.png`)" height="80px"> -->
          <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmitHandle()" status-icon>
            <!-- <el-form-item>
              <el-select v-model="$i18n.locale" class="w-percent-100">
                <el-option v-for="(val, key) in i18nMessages" :key="key" :label="val._lang" :value="key"></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item prop="username">
              <el-input v-model="dataForm.username" :placeholder="$t('login.username')">
                <span slot="prefix" class="el-input__icon">
                  <svg class="icon-svg" aria-hidden="true"><use xlink:href="#icon-user"></use></svg>
                </span>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input v-model="dataForm.password" type="password" :placeholder="$t('login.password')">
                <span slot="prefix" class="el-input__icon">
                  <svg class="icon-svg" aria-hidden="true"><use xlink:href="#icon-lock"></use></svg>
                </span>
              </el-input>
            </el-form-item>
            <el-form-item prop="captcha">
              <el-row :gutter="20">
                <el-col :span="14">
                  <el-input v-model="dataForm.captcha" :placeholder="$t('login.captcha')">
                    <span slot="prefix" class="el-input__icon">
                      <svg class="icon-svg" aria-hidden="true"><use xlink:href="#icon-safetycertificate"></use></svg>
                    </span>
                  </el-input>
                </el-col>
                <el-col :span="10" class="login-captcha">
                  <img :src="captchaPath" @click="getCaptcha()">
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="dataFormSubmitHandle()" class="w-percent-100">{{ $t('login.title') }}</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="login-footer">
          <!-- <p>Copyright © XX软件科技有限公司 All Rights Reserved</p> -->
          <!-- <p><a href="http://www.XX.cn/" target="_blank">XX软件科技有限公司</a></p> -->
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import debounce from 'lodash/debounce'
import { messages } from '@/i18n'
import { getUUID } from '@/utils'
export default {
  data () {
    return {
      i18nMessages: messages,
      captchaPath: '',
      dataForm: {
        username: '',
        password: '',
        uuid: '',
        captcha: '',
        grant_type: 'password'
      }
    }
  },
  computed: {
    dataRule () {
      return {
        username: [
          { required: true, message: this.$t('validate.required'), trigger: 'blur' }
        ],
        password: [
          { required: true, message: this.$t('validate.required'), trigger: 'blur' }
        ],
        captcha: [
          { required: true, message: this.$t('validate.required'), trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getCaptcha()
    // 动态删除J_auiTheme、J_elementTheme这两个css文件（否则登录页面背景变成白色）
    this.removeThemeColorLinks()
  },
  methods: {
    // 获取验证码
    getCaptcha () {
      this.dataForm.uuid = getUUID()
      this.captchaPath = `${window.SITE_CONFIG['apiURL']}/auth/captcha?uuid=${this.dataForm.uuid}`
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        const loading = this.$loading({
          lock: true,
          text: '正在登录...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.$http.post('/auth/oauth/token', this.dataForm,
          {
            headers: {
              'content-type': 'application/x-www-form-urlencoded',
              // 对应数据库中的client_id、client_secret，不能随便修改
              'Authorization': 'Basic aWNsb3VkOnJlbnJlbmlv'
            }
          }
        ).then(({ data: res }) => {
          if (res.code !== 0) { // 验证码不对时，后台不生成token直接抛出异常
            this.getCaptcha()
            loading.close()
            return this.$message.error(res.msg)
          }
          Cookies.set('access_token', res.access_token)
          Cookies.set('refresh_token', res.refresh_token)
          Cookies.set('opm_login_source', 'opm')
          loading.close()
          this.$router.replace({ name: 'home' })
        }).catch((e) => {
          loading.close()
          this.$message.error('服务器（OAUTH）出现异常：' + e)
        })
      })
    }, 1000, { 'leading': true, 'trailing': false }),
    // 登录页面中引入J_auiTheme、J_elementTheme的link文件后背景就变成白色了（原因未知），为了解决该问题需要在当前页面中删除这两个css文件
    removeThemeColorLinks () {
      var link1 = document.getElementById('J_auiTheme')
      link1 && link1.parentNode.removeChild(link1)

      var link2 = document.getElementById('J_elementTheme')
      link2 && link2.parentNode.removeChild(link2)
    }
  }
}
</script>
<style lang="scss" scoped>
  .my-loading {
    .el-loading-spinner {
      top: 50%;
      left: 50%;
      margin-left: -55px;
      background: rgba(0, 0, 0, 0.7);
      padding: 20px;
      border-radius: 4px;
      width: auto;
      text-align: center;
      position: absolute;

      i {
        color: #eee;
      }

      .el-loading-text {
        color: #eee;
      }
    }
  }
  .el-form-item {
    padding: 8px;
  }
</style>