<template>
  <el-dialog :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-tree
        v-loading="treeLoading"
        node-key="id"
        :props="props"
        ref="tree"
        lazy
        show-checkbox
        :load="load"
        :default-checked-keys="defaultCheckedKeys"
        highlight-current
        :default-expanded-keys="expandedKeys"
    >
    </el-tree>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data () {
    return {
      visible: false,
      dataForm: {
        contractId: '',
        subcontractorId: '',
        boqId: '',
      },
      props: {
        label: 'name',
        isLeaf: 'isLeaf',
        disabled: this.checkBoxDisabled
      },
      treeLoading: true,
      defaultCheckedKeys: [],
      expandedKeys: []
    }
  },
  computed: {
    dataRule () {
      return {
      }
    }
  },
  methods: {
    load(node, resolve) {
      let pid
      if (!node.data) {
        pid = 0
      } else {
        pid = node.data.id
      }
      this.$http.get(
          '/mps/wbs/children',
          {
            params: {
              'pid': pid,
              ...this.dataForm
            }
          }
      ).then(({ data: res }) => {
        if (res.code !== 0) {
          // 返回出错
          return this.$message.error(res.msg)
        }
        resolve(res.data)

        // 实现默认展开第一级节点
        if((!node || !node.parent) && res.data.length > 0) {
          this.expandedKeys = [res.data[0].id]
        }
      }).catch(() => {
        // 查询出错
        return this.$message.error('出错了')
      })
    },
    init () {
      this.visible = true
      this.getExistSubItemIdList()
    },
    // 查询已分解的部位id
    getExistSubItemIdList() {
      this.$http.get('/mps/subBoq/existSubItemIds',
          {
            params: this.dataForm
          }
      ).then(({ data: res }) => {
        if (res.code !== 0) {
          // 返回出错
          return this.$message.error(res.msg)
        }
        this.defaultCheckedKeys = res.data
        this.treeLoading = false
      }).catch(() => {
        // 查询出错
        return this.$message.error('出错了')
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$http.post(
          '/mps/subBoq/batchAdd',
          {
            subItemIds: this.$refs.tree.getCheckedKeys(true),
            ...this.dataForm
          }
      ).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.$message({
          message: this.$t('prompt.success'),
          type: 'success',
          duration: 500,
          onClose: () => {
            this.visible = false
            this.$emit('refreshDataList')
          }
        })
      }).catch(() => {})
    }, 1000, { 'leading': true, 'trailing': false }),
    checkBoxDisabled(data, node) {
      return !data.isLeaf || this.defaultCheckedKeys.includes(data.id)
    },
  }
}
</script>